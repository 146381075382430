import { BrowserRouter, Route, Routes } from 'react-router';

import Login from 'web/screens/Login';
import Invite from 'web/screens/Invite';

import UnauthorizedNotFound from 'web/screens/UnauthorizedNotFound';
import PrivacyPolicy from './PrivacyPolicy';

import RouteRedirect from 'web/routes/components/RouteRedirect';

export default function UnauthorizedApp() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />

				<Route path="/invitasjon/" element={<Invite />} />

				<Route
					path="/personvernerklaering"
					element={<PrivacyPolicy />}
				/>

				<Route path="/404/" element={<UnauthorizedNotFound />} />

				<Route path="*" element={<RouteRedirect redirect="/" />} />
			</Routes>
		</BrowserRouter>
	);
}

import { ThemeProvider } from 'styled-components';
import CssBaseline from '@mui/material/CssBaseline';

import theme from 'web/theme/theme';

export default function AppStylesProvider({ children }) {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline enableColorScheme />

			{children}
		</ThemeProvider>
	);
}

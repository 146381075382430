export const defaultServices = [
	{
		name: 'Betaling',
		billings: [],
	},
	{
		name: 'Bokføring',
		billings: [],
	},
	{
		name: 'Budsjettering',
		billings: [],
	},
	{
		name: 'Fakturering og oppfølging av fordringer',
		billings: [],
	},
	{
		name: 'Lønn og personal',
		billings: [],
	},
	{
		name: 'Oppbevaring av regnskapsmateriale, scannetranse',
		billings: [],
	},
	{
		name: 'Skattemelding',
		billings: [],
	},
	{
		name: 'Årsoppgjør',
		billings: [],
	},
];

export const defaultAssignments = [
	{
		name: 'Fakturering og oppfølging av utestående fordringer',
		comment:
			'Faktureringsoppdrag: Kunden har ansvar for komplette og oppdaterte faktureringsgrunnlag.',
		tasks: [
			{
				name: 'Fakturering',
				frequency: 'På forespørsel',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
			{
				name: 'Oppfølging av fordringer',
				frequency: 'På forespørsel',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
		],
	},
	{
		name: 'Lønn og personal',
		comment:
			'Kunden har ansvar for komplette og oppdaterte lønnsgrunnlag.\nKunde skal overlevere lønnsgrunnlag til regnskapsforetaket i henhold til spesifikasjon på oppdraget.Overleveringen skal skje ordnet og i samsvar med fast rutine.',
		tasks: [
			{
				name: 'Kunden leverer lønnsgrunnlag',
				frequency: 'Månedlig',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
			{
				name: 'Lønn til arbeidstakere',
				frequency: 'Månedlig',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
			{
				name: ' Innrapportere opplysninger (a-melding)',
				frequency: 'Månedlig',
				clientDeadline: '',
				contractorDeadline: '5. neste mnd.',
				active: false,
			},
			{
				name: 'Sammenstillingsoppgave til arbeidstakere',
				frequency: 'Årlig',
				clientDeadline: '',
				contractorDeadline: '31.januar',
				active: false,
			},
		],
	},
	{
		name: 'Bokføring',
		comment:
			'Bokføringen og utarbeidelse av pliktig regnskapsrapportering skal skje innen de frister som følger av lovgivningen.\nPeriodiske regnskapsrapporter skal utarbeides og gjøres tilgjengelig for kunden i henhold til spesifikasjonen på oppdraget.\nKunden har ansvar for komplette og oppdaterte bokføringsgrunnlag.\nKunden skal overlevere bilag og annet bokføringsgrunnlag til regnskapsforetaket i henhold til avtalte frister.\nOverleveringen skal skje ordnet og i samsvar med fast rutine.',
		tasks: [
			{
				name: 'Kunden leverer bilag og annen relevant informasjon til regnskapsfører',
				frequency: 'Løpende',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},

			{
				name: 'Spesifikasjoner av pliktig regnskapsrapportering i henhold til gjeldende lovkrav',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: '20. om 2 mnd.',
				active: false,
			},
			{
				name: 'Pliktig rapportering merverdiavgift ',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: '10. om 2 mnd.',
				active: false,
			},
			{
				name: 'Periodiske rapporter til kunden',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: '20. om 2 mnd.',
				active: false,
			},
		],
	},
	{
		name: 'Betalingsoppdrag',
		comment: '',
		tasks: [
			{
				name: 'Inngående fakturaer mv.',
				frequency: 'Løpende',
				clientDeadline: 'En uke før forfall',
				contractorDeadline: 'Forfallsdato',
				active: false,
			},
			{
				name: 'Merverdiavgift',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: 'Forfallsdato',
				active: false,
			},
			{
				name: 'Lønn til arbeidstakere',
				frequency: 'Månedlig',
				clientDeadline: '5 virkedager før lønningsdato',
				contractorDeadline: '30. hver måned',
				active: false,
			},
			{
				name: 'Arbeidsgiveravgift',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: 'Forfallsdato',
				active: false,
			},
			{
				name: 'Skattetrekk og utleggstrekk',
				frequency: '2-månedlig',
				clientDeadline: '',
				contractorDeadline: 'Forfallsdato',
				active: false,
			},
			{
				name: 'Betaling kun på forespørsel i hvert enkelt tilfelle',
				frequency: 'Løpende',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
		],
	},
	{
		name: 'Årsoppgjør',
		comment:
			'Levering av årsregnskap til Regnskapsregisteret skjer innen lovbestemte frister.\nLevering av skattemelding skjer innen lovbestemte frister.\nLevering av tredjepartsopplysninger skjer innen lovbestemte frister.',
		tasks: [
			{
				name: 'Årsregnskap ',
				frequency: 'Årlig',
				clientDeadline: '',
				contractorDeadline: '30. juni',
				active: false,
			},
			{
				name: 'Skattemelding for formues- og inntektsskatt',
				frequency: 'Årlig',
				clientDeadline: '',
				contractorDeadline: '31. mai',
				active: false,
			},
			{
				name: 'Tredjepartsopplysninger',
				frequency: 'Årlig',
				clientDeadline: '',
				contractorDeadline: '31. januar',
				active: false,
			},
		],
	},
	{
		name: 'Oppbevaring',
		comment:
			'Oppbevaring av regnskapsmateriale skjer i henhold til bokføringsforskriften.',
		tasks: [
			{
				name: 'Oppbevaring av regnskapsmateriale',
				frequency: 'Løpende',
				clientDeadline: '',
				contractorDeadline: '',
				active: false,
			},
		],
	},
];

export const defaultHourlyRates = [
	{
		name: 'Aksjonærregisteroppgave',
		value: '1 130,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Bokføring/regnskapsarbeid/lønningsarbeid m/etterkontroll',
		value: '960,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Budsjettarbeid',
		value: '1 130.00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Databistand',
		value: '1 130,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Faktureringsarbeid/purringer og renteberegning',
		value: '960,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Konsulent',
		value: '960,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Kontroll regnskap fravalgt revisor',
		value: '1 170,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Kundemøte/konsulentarbeid/administrasjonsarbeid',
		value: '960,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Lovpålagt kvalitetssikring',
		value: '1 130,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Planlegging og tilrettelegging',
		value: '1 130.00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Rådgivning regnskapsfører, høy sats',
		value: '1 475,00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Rådgivning regnskapsfører, lav sats',
		value: '1 130.00',
		unit: 'Per time',
		active: true,
	},
	{
		name: 'Årsoppgjør, ekstraarbeid',
		value: '1 130,00',
		unit: 'Per time',
		active: true,
	},
];

export const defaultFixedRates = [
	{
		name: 'Autobank innbetalinger',
		value: '3,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Autobank utbetalinger',
		value: ' 3,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Bankavstemming',
		value: '0,50',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Bilagspris',
		value: '6,50',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Efaktura B2C',
		value: '4,50',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'EHF inng/utg',
		value: '3,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Elektronisk dokumentlagring/Nobelflow',
		value: '50,00',
		unit: 'Per måned',
		active: true,
	},
	{
		name: 'Fakturaprint',
		value: '15,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Innsendt og kontrollert årsregnskap',
		value: '1 100,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Inntektsmelding',
		value: '108,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Lisens årsoppgjørsprogram',
		value: '400,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Lønn aktive ansatte 0-49 stk',
		value: '11,80',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Lønn aktive ansatte 50+ stk',
		value: '7,60',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Månedlig A-melding',
		value: '260,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Ocrtolk',
		value: '3,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Sammenstillingsoppgave',
		value: '100,00',
		unit: 'Per stykk',
		active: true,
	},
	{
		name: 'Utarbeidelse protokoll',
		value: '1 100,00',
		unit: 'Per stykk',
		active: true,
	},
];

export const defaultAttestationRoutines = [
	{
		name: 'Kunden godkjenner betalingsfil i bank (Kunden gjennomfører utbetalingen).',
		active: false,
	},
	{
		name: 'Kunden godkjenner betalingsforslagene i systemløsningen.',
		active: false,
	},
	{
		name: 'Kunden attesterer betalingsliste mottatt fra Regnskapsforetaket. Listen sendes i retur til Regnskapsforetaketpå e-post, som foretar utbetalingene.',
		active: false,
	},
	{
		name: 'Betalingsgrunnlagene er godkjent for utbetaling fra Kundens side i det de er attestert i systemet for dokumentgodkjenning.',
		active: false,
	},
	{
		name: 'Betalingsgrunnlagene er godkjent for utbetaling fra Kundens side i det de er oversendt Regnskapsforetaket.',
		active: false,
	},
];

export const defaultSoftware = [
	{
		name: 'Unimicro',
		license: null,
		vendor: 'Uni Micro AS',
		adminRights: false,
		modules: [
			{
				name: 'Selskapslisens',
				unit: 'Per selskap',
				price: '55.00',
				active: true,
			},
			{
				name: 'Unimicro Komplett (RSLT)',
				unit: 'Per bruker',
				price: '1 104.00',
				active: false,
			},
			{
				name: 'Regnskap',
				unit: 'Per bruker',
				price: '458.00',
				active: false,
			},
			{
				name: 'Salg (tilbud/ordre/faktura)',
				unit: 'Per bruker',
				price: '270.00',
				active: false,
			},
			{
				name: 'Lønn',
				unit: 'Per bruker',
				price: '519.00',
				active: false,
			},
			{
				name: 'Time',
				unit: 'Per bruker',
				price: '323.00',
				active: false,
			},
			{
				name: 'Timeregistrering, 1-49 ansatte',
				unit: 'Per bruker',
				price: '62.00',
				active: false,
			},
			{
				name: 'Timeregistrering, 50+ ansatte',
				unit: 'Per bruker',
				price: '54.00',
				active: false,
			},
			{
				name: 'Fakturagodkjenning',
				unit: 'Per bruker',
				price: '54.00',
				active: true,
			},
			{
				name: 'Ledelsesinfo',
				unit: 'Per bruker',
				price: '54.00',
				active: true,
			},
			{
				name: 'Autobank-godkjenning',
				unit: 'Per bruker',
				price: '54.00',
				active: false,
			},
			{
				name: 'Lønnsgodkjenner',
				unit: 'Per bruker',
				price: '54.00',
				active: false,
			},
			{
				name: 'Lønn, 0-49 ansatte',
				unit: 'Per bruker',
				price: '11.80',
				active: false,
			},
			{
				name: 'Lønn, 50+ ansatte',
				unit: 'Per bruker',
				price: '7.60',
				active: false,
			},
			{
				name: 'Fraværsrapportering',
				unit: 'Per bruker',
				price: '3.20',
				active: false,
			},
			{
				name: 'Landbruk',
				unit: 'Per selskap, månedlig',
				price: '200',
				active: false,
			},
			{
				name: 'Årsavslutning',
				unit: 'Per selskap, årlig',
				price: '400.00',
				active: false,
			},
			{
				name: 'Backup, 5 år',
				unit: 'Per kjøp',
				price: '500.00',
				active: true,
			},
			{
				name: 'Backup, 10 år',
				unit: 'Per kjøp',
				price: '1 000.00',
				active: false,
			},
		],
	},
	{
		name: 'Uni Økonomi V3',
		license: null,
		vendor: 'Uni Micro',
		adminRights: false,
		modules: null,
	},
	{
		name: 'Tripletex',
		license: null,
		vendor: 'Tripletex AS',
		adminRights: false,
		modules: null,
	},
	{
		name: 'Duett',
		license: null,
		vendor: 'Duett AS',
		adminRights: false,
		modules: [
			{
				name: 'Selskapslisens',
				unit: 'Per selskap',
				price: '99.00',
				active: false,
			},
			{
				name: 'Selskapslisens',
				unit: 'Per selskap',
				price: '199.00',
				active: false,
			},
			{
				name: 'Min side-bruker',
				unit: 'Per bruker',
				price: '0.00',
				active: false,
			},
			{
				name: 'Bankbruker',
				unit: 'Per bruker',
				price: '0.00',
				active: false,
			},
			{
				name: 'VPN-bruker',
				unit: 'Per bruker',
				price: '0.00',
				active: false,
			},
		],
	},
	{
		name: 'PowerOffice Go',
		license: null,
		vendor: 'PowerOffice AS',
		adminRights: false,
		modules: null,
	},
	{
		name: 'Bedriftens egen server',
		license: null,
		vendor: '',
		adminRights: false,
		modules: null,
	},
];

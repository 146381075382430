import { useQuery, useMutation } from '@apollo/client';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';

import { useUser } from 'web/contexts/UserContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';

import TextField from 'components/form/TextField';
import Form from 'components/form/Form';
import SubmitButton from 'components/form/SubmitButton';
import UserRolesList from 'web/components/UserRolesList';

import { FIND_USER_PROFILE } from 'api/queries/userQueries';
import { UPDATE_USER_PROFILE } from 'api/mutations/userMutations';

export default function UsersProfile() {
	const { user: me } = useUser();
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const {
		data: { user } = { user: {} },
		loading: findUserProfileLoading,
		error: findUserProfileError,
	} = useQuery(FIND_USER_PROFILE, {
		variables: {
			_id: me._id,
		},
	});

	const [
		updateOneUser,
		{ loading: updateOneUserLoading, error: updateOneUserError },
	] = useMutation(UPDATE_USER_PROFILE, {
		refetchQueries: ['findUserProfile', 'currentUser'],
	});

	if (!user) return null;

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Min profil2',
				},
			]}
		>
			<NarrowContent>
				<Form
					values={user}
					key={user._id}
					isLoading={findUserProfileLoading || updateOneUserLoading}
					onSubmit={async values => {
						try {
							const verifyFields = [];

							if (values.email !== user.email) {
								verifyFields.push('e-post');
							}

							if (values.phone !== user.phone) {
								verifyFields.push('telefon');
							}

							if (verifyFields.length > 0) {
								const verified = await verify({
									title: 'Bekreft endringer',
									text: `Du har endret felt som trenger verifisering: ${verifyFields.join(
										', '
									)}. Er du sikker på at du vil lagre og gjennomføre verifiseringen?`,
								});

								if (!verified) {
									notify(
										'Endringer ble ikke lagret.',
										'warning'
									);

									return;
								}
							}

							const { data } = await updateOneUser({
								variables: {
									_id: me._id,
									...values,
								},
							});

							if (data?.user) {
								notify('Profilen ble oppdatert!');
							}
						} catch (err) {
							console.error(err);
						}
					}}
				>
					<ErrorMessage
						errors={[findUserProfileError, updateOneUserError]}
					/>

					<TextField name="name" label="Navn" required />

					<TextField
						name="email"
						type="email"
						label="E-post"
						required
					/>

					<TextField
						name="phone"
						type="tel"
						pattern="^[\d\s+()]{8,}$"
						title="Skriv inn et gyldig telefonnummer."
						label="Telefon"
						required
					/>

					<UserRolesList roles={user.userRoles} />

					<SubmitButton variant="contained" size="large">
						Oppdater profilen
					</SubmitButton>
				</Form>
			</NarrowContent>
		</DesktopLayout>
	);
}

import styled from 'styled-components';
import { Link } from 'react-router';

import Button from '@mui/material/Button';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CreatedInfo from 'web/components/CreatedInfo';
import UpdatedInfo from 'web/components/UpdatedInfo';

import UserUpdateContactInfo from 'web/screens/user/usersEdit/components/UserUpdateContactInfo';
import ResendInvite from 'web/screens/user/usersEdit/components/ResendInvite';

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	width: 100%;
	max-width: 400px;
	button {
		margin-bottom: 16px;
	}
`;

const SaveInfo = styled.div`
	margin-top: 40px;
`;

export default function Settings({ user }) {
	return (
		<div>
			<h3>Innstillinger</h3>

			<Actions>
				<Button
					variant="outlined"
					size="large"
					to="tilganger"
					component={Link}
					startIcon={<FolderOutlinedIcon />}
				>
					Mappetilganger
				</Button>
			</Actions>

			{user.role !== 'silent' && <UserUpdateContactInfo user={user} />}

			{!user.hasLoggedIn && user.email && <ResendInvite user={user} />}

			<SaveInfo>
				<CreatedInfo
					createdAt={user?.createdAt}
					createdBy={user?.createdByDisplayName}
				/>

				<UpdatedInfo
					updatedAt={user?.updatedAt}
					updatedBy={user?.updatedByDisplayName}
				/>
			</SaveInfo>
		</div>
	);
}

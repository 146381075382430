import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import Stack from '@mui/material/Stack';

import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';

import ErrorAlert from 'components/ErrorAlert';

import Form, { Checkbox, TextField } from 'web/form/Form';

import { INSERT_ONE_USER, INVITE_ONE_USER } from 'api/mutations/userMutations';

export default function UsersCreateDialog({
	open,
	onClose,
	onSubmitSuccess,
	title = 'Opprett bruker',
	user = {},
	companyId = null,
	defaultRole = 'silent',
	extraFormFields,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth="sm"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>

			<DialogCloseButton onClick={onClose} />

			<UserCreateDialogForm
				user={user}
				companyId={companyId}
				onClose={onClose}
				onSubmitSuccess={onSubmitSuccess}
				defaultRole={defaultRole}
				extraFormFields={extraFormFields}
			/>
		</Dialog>
	);
}

// @TODO: Refactor to by defaul only have fields for name, email and phone
// and then hav props for other possible fields
export function UserCreateDialogForm({
	onClose,
	onSubmitSuccess,
	user = {},
	defaultRole = 'silent',
	extraFormFields,
}) {
	const { verify } = useConfirmDialog();

	const [
		insertOneUser,
		{ loading: insertOneUserLoading, error: insertOneUserError },
	] = useMutation(INSERT_ONE_USER, {
		refetchQueries: ['findOneTenant', 'paginateUsers'],
	});

	const [
		inviteOneUser,
		{ loading: inviteOneUserLoading, error: inviteOneUserError },
	] = useMutation(INVITE_ONE_USER);

	const isLoading = insertOneUserLoading || inviteOneUserLoading;

	return (
		<Form
			values={{
				name: user?.name || '',
				email: '',
				phone: '',
				sendInvite: true,
			}}
			onSubmit={async (values, { setError }) => {
				try {
					const { sendInvite, ...newUserValues } = values;

					if (sendInvite && !newUserValues?.email) {
						setError('email', {
							type: 'manual',
							message:
								'Brukeren må ha en gyldig e-post for å bli invitert.',
						});

						return;
					}

					let variables = {
						role: defaultRole,
						...newUserValues,
					};

					if (
						sendInvite &&
						!(await verify({
							title: 'Sende invitasjon?',
							text: `Er du sikker på at du ønsker å sende invitasjon til den nye brukeren?`,
						}))
					) {
						return;
					}
					const { data: { insertOneUser: newUser } = {} } =
						await insertOneUser({
							variables,
						});

					if (sendInvite && newUser._id) {
						await inviteOneUser({
							variables: {
								_id: newUser._id,
							},
						});
					}

					onSubmitSuccess(newUser, values);
				} catch (e) {
					console.warn(e);
				}
			}}
		>
			<DialogContent>
				<ErrorAlert error={insertOneUserError} />
				<ErrorAlert error={inviteOneUserError} />

				<Stack spacing={3}>
					<TextField
						name="name"
						label="Navn"
						disabled={!!user?.name}
						required
					/>

					<TextField name="email" label="E-post" type="email" />

					<TextField
						name="phone"
						type="tel"
						title="Skriv inn et gyldig telefonnummer."
						label="Telefon"
					/>

					{extraFormFields}

					<Checkbox name="sendInvite" label="Send invitasjon" />
				</Stack>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Avbryt</Button>

				<SubmitButton isLoading={isLoading} />
			</DialogActions>
		</Form>
	);
}

function SubmitButton({ isLoading }) {
	const {
		formState: { disabled: formDisabled },
	} = useFormContext();

	const disabled = isLoading || formDisabled;

	return (
		<Button variant="contained" type="submit" disabled={disabled}>
			{!isLoading ? 'Opprett bruker' : 'Oppretter...'}
		</Button>
	);
}

import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import ErrorAlert from 'components/ErrorAlert';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import { formatDate } from 'utils/dates';

import { SEND_USER_CONTACT_INFO_UPDATE_NOTIFICATION } from 'api/mutations/userMutations';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 400px) auto;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const ErrorWrapper = styled.div`
	width: 100%;
	max-width: 700px;
`;

export default function UserUpdateContactInfo({ user }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [sendContactInfoUpdateNotification, { loading, error }] = useMutation(
		SEND_USER_CONTACT_INFO_UPDATE_NOTIFICATION,
		{
			refetchQueries: ['findOneUser'],
		}
	);

	return (
		<>
			<Wrapper>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					type="button"
					startIcon={<BadgeOutlinedIcon />}
					disabled={loading}
					onClick={async () => {
						try {
							if (
								!(await verify({
									title: 'Oppdatering av brukerinformasjon',
									text: 'Vil du sende en notifikasjon til brukeren om å oppdatere kontaktinformasjonen sin?',
								}))
							) {
								return;
							}

							const { data } =
								await sendContactInfoUpdateNotification({
									variables: {
										userId: user._id,
									},
								});

							if (data.sent) {
								notify('Notifikasjon sendt til bruker');
							}
						} catch (err) {
							console.error(err);
							notify(
								'Noe gikk galt og notifikasjon kunne ikke sendes',
								'error'
							);
						}
					}}
				>
					Be om oppdatering av brukerinfo
				</Button>

				<div>
					{user.forceUserVerificationDate && (
						<div>
							<strong>Siste notifikasjon: </strong>

							{formatDate(
								user.forceUserVerificationDate,
								'DD.MM.YYYY [kl] HH:mm'
							)}
						</div>
					)}

					{user.userLastVerificationDate && (
						<div>
							<strong>Sist verifisert: </strong>

							{formatDate(
								user.userLastVerificationDate,
								'DD.MM.YYYY [kl] HH:mm'
							)}
						</div>
					)}
				</div>
			</Wrapper>

			{error && (
				<ErrorWrapper>
					<ErrorAlert error={error} />
				</ErrorWrapper>
			)}
		</>
	);
}

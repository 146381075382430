import {
	ApolloProvider,
	ApolloClient,
	HttpLink,
	ApolloLink,
	InMemoryCache,
	defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export default function GraphqlProvider({ children }) {
	const httpLink = new HttpLink({
		uri: window.location.origin + '/graphql',
		credentials: 'same-origin',
	});

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.warn(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);

		if (networkError) console.warn(`[Network error]: ${networkError}`);
	});

	const cache = new InMemoryCache({
		dataIdFromObject: object => {
			switch (object.__typename) {
				case 'UserRole':
					return `${object.id}-${object.role}`;
				case 'UserTenantRole':
					return `${object.key}-${object.role}`;
				default:
					return defaultDataIdFromObject(object);
			}
		},
	});

	const apolloClient = new ApolloClient({
		link: ApolloLink.from([errorLink, httpLink]), // Correct ordering
		cache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

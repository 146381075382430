import styled from 'styled-components';
import { Link } from 'react-router';

import LogoSvg from 'assets/images/logo.svg';
import { UnderlineLink } from 'web/components/Link';
import DesktopLayout, { WideContent } from 'web/components/Layout';

import PrivacyPolicyText from 'web/components/privacyPolicy/PrivacyPolicyText';

const Content = styled(WideContent)`
	padding-top: 0;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding: 0 20px 20px;
	max-width: 1200px;
	margin: auto;
`;

const BackLink = styled.div`
	padding: 20px;
	text-align: right;
`;

const LogoLink = styled(Link)`
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	color: inherit;
	text-decoration: none;
	font-weight: 700;
`;

const LogoImage = styled.img`
	height: 20px;
`;

export default function PrivacyPolicy({ isAuthorized = false }) {
	if (isAuthorized) {
		return <AuthoriredPrivacyPolicy />;
	}

	return (
		<>
			<BackLink>
				<UnderlineLink to={'/'}>Innlogging</UnderlineLink>
			</BackLink>
			<Wrapper>
				<LogoLink to={'/'}>
					<LogoImage src={LogoSvg} alt="Admento Falstad" />
					<span>Admento Falstad</span>
				</LogoLink>

				<PrivacyPolicyText />
			</Wrapper>
		</>
	);
}

function AuthoriredPrivacyPolicy() {
	return (
		<DesktopLayout
			title="Personvernerklæring"
			breadcrumbs={[
				{
					label: 'Personvernerklæring',
				},
			]}
		>
			<Content>
				<PrivacyPolicyText />
			</Content>
		</DesktopLayout>
	);
}

import { useReducer, createContext, useContext } from 'react';
import { getLocalStorageState, setLocalStorageState } from 'utils/localStorage';

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

// toLocaleString('nb-NO', { month: "long" }) display the month name in lowercase.
const monthLabels = {
	0: 'Januar',
	1: 'Februar',
	2: 'Mars',
	3: 'April',
	4: 'Mai',
	5: 'Juni',
	6: 'Juli',
	7: 'August',
	8: 'September',
	9: 'Oktober',
	10: 'November',
	11: 'Desember',
};

export const AppFiltersContext = createContext();

export function useAppFilters() {
	const context = useContext(AppFiltersContext);

	if (context === undefined) {
		throw new Error(
			'useApplFilters must be used within a AppFiltersProvider'
		);
	}

	return context;
}

export function renameFilterKeys(obj, keyMap) {
	return Object.keys(obj)
		.filter(key => Object.prototype.hasOwnProperty.call(keyMap, key)) // Safely check keys in keyMap
		.reduce((acc, key) => {
			const newKey = keyMap[key];
			acc[newKey] = obj[key];

			return acc;
		}, {});
}

export const actionTypes = {
	SET_SEARCH: 'search',
	SET_YEAR: 'year',
	SET_MONTH: 'month',
	SET_TASK: 'task',
	SET_DEPARTMENT: 'department',
	SET_ACCOUNTANT: 'accountant',
	SET_PROJECT_MANAGER: 'projectManager',
	SET_ACCOUNT_MANAGER: 'accountManager',
	SET_PAYROLL_MANAGER: 'payrollManager',
	SET_ANNUAL_ACCOUNTS_MANAGER: 'annualAccountsManager',
	SET_CONTROLLER: 'controller',
	SET_AUDITOR: 'auditor',
	SET_ACCOUNTANT_LEGACY: 'accountantLegacy',
	SET_ACCOUNTANT_ROLE: 'accountantRole',
	SET_ACCOUNTANT_ROLE_LEGACY: 'accountantRoleLegacy',
	SET_USER_ROLE: 'userRole',
	SET_COMPANY: 'company',
	SET_SELECTED_COMPANY: 'selectedCompany',
	SET_AUDITING_COMPANY: 'auditingCompany',
	SET_ACCOUNT_TYPE: 'accountType',
	SET_CONTROL_TYPE: 'controlType',
	RESET_FILTERS: 'resetFilters',
};

const localStorageTypes = {
	task: 'task-filter',
	department: 'department-filter',
	accountant: 'accountant-filter',
	projectManager: 'project-manager-filter',
	accountManager: 'account-manager-filter',
	payrollManager: 'payroll-manager-filter',
	annualAccountsManager: 'annual-accounts-manager-filter',
	controller: 'controller-filter',
	auditor: 'auditor-filter',
	accountantLegacy: 'accountant-legacy-filter',
	accountantRole: 'accountant-role-filter',
	accountantRoleLegacy: 'accountant-role-legacy-filter',
	userRole: 'user-role-filter',
	company: 'company-filter',
	selectedCompany: 'selected-company-filter',
	auditingCompany: 'auditing-company',
	accountType: 'account-type-filter',
	controlType: 'control-types-filter',
};

// We dont't save the current year and month in local storage because we want to reset the filters when the user reloads the page or logs out.
const initialState = {
	search: '',
	year: {
		value: currentYear,
		label: currentYear.toString(),
	},
	month: {
		value: currentMonth,
		label: monthLabels[currentMonth],
	},
	task: getLocalStorageState(localStorageTypes.task, null),
	department: getLocalStorageState(localStorageTypes.department, null),
	accountant: getLocalStorageState(localStorageTypes.accountant, null),
	projectManager: getLocalStorageState(
		localStorageTypes.projectManager,
		null
	),
	accountManager: getLocalStorageState(
		localStorageTypes.accountManager,
		null
	),
	payrollManager: getLocalStorageState(
		localStorageTypes.payrollManager,
		null
	),
	annualAccountsManager: getLocalStorageState(
		localStorageTypes.annualAccountsManager,
		null
	),
	controller: getLocalStorageState(localStorageTypes.controller, null),
	auditor: getLocalStorageState(localStorageTypes.auditor, null),
	accountantLegacy: getLocalStorageState(
		localStorageTypes.accountantLegacy,
		null
	),
	accountantRole: getLocalStorageState(
		localStorageTypes.accountantRole,
		null
	),
	accountantRoleLegacy: getLocalStorageState(
		localStorageTypes.accountantRoleLegacy,
		null
	),
	userRole: getLocalStorageState(localStorageTypes.userRole, null),
	company: getLocalStorageState(localStorageTypes.company, null),
	selectedCompany: getLocalStorageState(
		localStorageTypes.selectedCompany,
		null
	),
	auditingCompany: getLocalStorageState(
		localStorageTypes.auditingCompany,
		null
	),
	accountType: getLocalStorageState(localStorageTypes.accountType, null),
	controlType: getLocalStorageState(localStorageTypes.controlType, {
		value: 'arsregnskap-skattemelding',
		label: 'Årsregnskap og skattemelding',
	}),
};

const checkPayloadValue = payload => {
	if (payload?.value === '') {
		return null;
	}
	return payload;
};

function reducer(state, action) {
	const value = checkPayloadValue(action.payload);
	const stateKey =
		actionTypes[
			Object.keys(actionTypes).find(
				key => actionTypes[key] === action.type
			)
		];

	switch (action.type) {
		case actionTypes.SET_SEARCH:
		case actionTypes.SET_YEAR:
		case actionTypes.SET_MONTH:
			return { ...state, [stateKey]: action.payload };
		case actionTypes.RESET_FILTERS:
			return { ...state, ...action.payload };
		default:
			setLocalStorageState(localStorageTypes[stateKey], value); //  Save the filter value in local storage.
			return { ...state, [stateKey]: action.payload };
	}
}

export default function AppFiltersProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);

	// Only returns filters that have a value.
	const activeFilters = Object.keys(state).reduce((acc, key) => {
		if (
			state[key] !== null &&
			state[key] !== '' &&
			state[key].value !== ''
		) {
			acc[key] = state[key].value;
		}
		return acc;
	}, {});

	const resetFilters = filterKeys => {
		try {
			if (!filterKeys) {
				console.warn('resetFilters: No filters to reset.');
				return true;
			}

			const resetPayload = filterKeys.reduce((acc, key) => {
				switch (key) {
					case 'month':
						acc[key] = initialState.month;
						break;
					case 'year':
						acc[key] = initialState.year;
						break;
					default:
						setLocalStorageState(localStorageTypes[key], null);
						acc[key] = null;
						break;
				}
				return acc;
			}, {});

			dispatch({
				type: actionTypes.RESET_FILTERS,
				payload: { ...resetPayload },
			});
			return true;
		} catch (error) {
			console.error('resetFilters: ', error);
			return false;
		}
	};

	return (
		<AppFiltersContext.Provider
			value={{
				...state,
				dispatch,
				activeFilters,
				resetFilters,
			}}
		>
			{children}
		</AppFiltersContext.Provider>
	);
}

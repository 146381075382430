import AppStylesProvider from 'web/contexts/AppStylesContext';
import DateProvider from 'web/contexts/DateContext';
import GraphqlProvider from 'web/contexts/GraphqlContext';
import AppDataProvider from 'web/contexts/AppDataContext';
import SnackbarProvider from 'web/contexts/SnackbarContext';
import UserProvider from 'web/contexts/UserContext';
import MoveDocumentNodeModalProvider from 'web/contexts/MoveDocumentNodeModalContext';
import RenameDocumentNodeModalProvider from 'web/contexts/RenameDocumentNodeModalContext';
import ConfirmProvider from 'web/contexts/ConfirmContext';
import AppFiltersProvider from 'web/contexts/AppFiltersContext';

export default function WebProviders({ children }) {
	return (
		<AppStylesProvider>
			<DateProvider>
				<GraphqlProvider>
					<AppDataProvider>
						<SnackbarProvider>
							<UserProvider>
								<MoveDocumentNodeModalProvider>
									<RenameDocumentNodeModalProvider>
										<ConfirmProvider>
											<AppFiltersProvider>
												{children}
											</AppFiltersProvider>
										</ConfirmProvider>
									</RenameDocumentNodeModalProvider>
								</MoveDocumentNodeModalProvider>
							</UserProvider>
						</SnackbarProvider>
					</AppDataProvider>
				</GraphqlProvider>
			</DateProvider>
		</AppStylesProvider>
	);
}

import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import Collapse from '@mui/material/Collapse';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import { InlineLoading } from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout from 'web/components/Layout';
import ContentWithSidebarFilters from 'web/components/ContentWithSidebarFilters';

const Section = styled.div`
	margin-bottom: 30px;
`;

import { GET_COMPANY_CONTROLS_STATISTICS } from 'api/queries/companyControlQueries';

const displayFilters = [
	'controlType',
	'year',
	'department',
	'accountant',
	'accountManager',
	'payrollManager',
	'annualAccountsManager',
	'controller',
	'auditor',
];

function categorySum(questions, property) {
	return questions.reduce((sum, question) => {
		return sum + (question[property] || 0);
	}, 0);
}

function totalSum(categories, property) {
	return categories.reduce((totalSum, category) => {
		const sum = categorySum(category.questions, property);

		return totalSum + sum;
	}, 0);
}

export default function ControlsStatistics() {
	return (
		<DesktopLayout
			title="Statistikk"
			breadcrumbs={[
				{
					label: 'Statistikk',
				},
			]}
		>
			<ContentWithSidebarFilters displayFilters={displayFilters}>
				<ControlsStatisticsContent />
			</ContentWithSidebarFilters>
		</DesktopLayout>
	);
}

const ControlStatistics = styled.div`
	position: relative;
`;

const CategoryInfo = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	font-size: 0.9rem;
	font-weight: bold;
	display: grid;
	grid-template-columns: 1fr repeat(3, 55px);
	gap: 10px;
	margin-bottom: 10px;
	padding: 0 20px;
	text-transform: uppercase;
`;

const CategoryInfoItem = styled.div`
	text-align: center;
`;

const NoContentMessage = styled.div`
	font-size: 1.2rem;
	font-style: italic;
	text-align: center;
	margin-top: 120px;
`;

function ControlsStatisticsContent() {
	const {
		year,
		controlType,
		department,
		accountant,
		accountManager,
		payrollManager,
		annualAccountsManager,
		controller,
		auditor,
	} = useAppFilters();

	const {
		data: { controlStatistics } = { controlStatistics: [] },
		loading,
		error,
	} = useQuery(GET_COMPANY_CONTROLS_STATISTICS, {
		variables: {
			filter: {
				departmentId: department?.value,
				year: year.value,
				controlType: controlType.value,
				accountantId: accountant?.value || null,
				accountManagerId: accountManager?.value || null,
				payrollManagerId: payrollManager?.value || null,
				annualAccountsManagerId: annualAccountsManager?.value || null,
				controllerId: controller?.value || null,
				auditorId: auditor?.value || null,
			},
		},
	});

	if (loading) {
		return <InlineLoading />;
	}

	return (
		<>
			<ErrorMessage errors={error} />

			<div>
				{controlStatistics.length > 0 ? (
					<ControlStatistics>
						<CategoryInfo>
							<div></div>

							<CategoryInfoItem>OK</CategoryInfoItem>

							<CategoryInfoItem>Avvik</CategoryInfoItem>

							<CategoryInfoItem>Prosent</CategoryInfoItem>
						</CategoryInfo>

						{controlStatistics.map(category => (
							<Category
								key={category.categoryName}
								category={category}
							/>
						))}

						<TotalSummarySection
							controlStatistics={controlStatistics}
						/>
					</ControlStatistics>
				) : (
					<NoContentMessage>
						Denne filtreringen inneholder ingen data!
					</NoContentMessage>
				)}
			</div>
		</>
	);
}

const CategoryWrapper = styled.div`
	background-color: ${p => p.theme.palette.background.paper};
	margin-bottom: 5px;
`;

const CategoryHeader = styled.button`
	font-size: 1rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr repeat(3, 55px);
	gap: 10px;
	align-items: center;
	padding: 20px;
	text-align: left;
	width: 100%;
`;

const CategoryTitle = styled.div`
	font-weight: bold;
	flex: 1;
	margin: 0;
`;

const CategoryHeaderInfoItem = styled.div`
	opacity: ${p => (p.$visible ? 1 : 0)};
	transition: opacity 0.5s;
	text-align: center;
`;

const CategoryContent = styled.div`
	padding: 0 20px 20px 20px;
`;

function Category({ category }) {
	const [expanded, setExpanded] = useState(false);

	const okCount = categorySum(category.questions, 'okCount');
	const deviationCount = categorySum(category.questions, 'deviationCount');
	const total = okCount + deviationCount;
	const percentage = total > 0 ? (deviationCount / total) * 100 : 0;

	return (
		<CategoryWrapper>
			<CategoryHeader
				type="button"
				onClick={() => setExpanded(prevState => !prevState)}
			>
				<CategoryTitle>{category.categoryName}</CategoryTitle>

				<CategoryHeaderInfoItem $visible={!expanded}>
					{okCount}
				</CategoryHeaderInfoItem>

				<CategoryHeaderInfoItem $visible={!expanded}>
					{deviationCount}
				</CategoryHeaderInfoItem>

				<CategoryHeaderInfoItem $visible={!expanded}>
					{percentage.toFixed(0)}&nbsp;%
				</CategoryHeaderInfoItem>
			</CategoryHeader>

			<Collapse in={expanded}>
				<CategoryContent>
					<Questions
						questions={category.questions}
						categoryName={category.categoryName}
					/>

					<Summary
						okCount={okCount}
						deviationCount={deviationCount}
						percentage={percentage}
					/>
				</CategoryContent>
			</Collapse>
		</CategoryWrapper>
	);
}

function Questions({ questions, categoryName }) {
	if (questions.length === 0) {
		return <p>Denne kategorien inneholder ingen spørsmål!</p>;
	}

	return (
		<div>
			{questions.map(question => (
				<Question
					key={question.label}
					question={question}
					categoryName={categoryName}
				/>
			))}
		</div>
	);
}

const QuestionWrapper = styled.button`
	background-color: transparent;
	border: none;
	border-bottom: 1px solid ${p => p.theme.palette.borderColor.default};
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr repeat(3, 55px);
	align-items: center;
	gap: 10px;
	margin-bottom: 8px;
	padding-bottom: 8px;
	text-align: left;
	width: 100%;

	&:last-of-type {
		border-bottom: none;
	}
`;

const QuestionTitle = styled.div``;

const QuestionSummaryItem = styled.div`
	text-align: center;
`;

function Question({ question, categoryName }) {
	const [expanded, setExpanded] = useState(false);
	const okCount = question.okCount || 0;
	const deviationCount = question.deviationCount || 0;
	const total = okCount + deviationCount;
	const percentage = total > 0 ? (deviationCount / total) * 100 : 0;

	return (
		<QuestionWrapper
			type="button"
			onClick={() => setExpanded(prev => !prev)}
		>
			<QuestionTitle>{question.label}</QuestionTitle>

			<QuestionSummaryItem>{okCount}</QuestionSummaryItem>

			<QuestionSummaryItem>{deviationCount}</QuestionSummaryItem>

			<QuestionSummaryItem>
				{percentage.toFixed(0)}&nbsp;%
			</QuestionSummaryItem>

			<Collapse in={expanded}>
				<QuestionDetails
					question={question}
					categoryName={categoryName}
				/>
			</Collapse>
		</QuestionWrapper>
	);
}

const QuestionDetailsWrapper = styled.div`
	margin: 20px;
`;

function QuestionDetails({ question, categoryName }) {
	return (
		<QuestionDetailsWrapper>
			<pre>{JSON.stringify({ categoryName, ...question }, null, 4)}</pre>
		</QuestionDetailsWrapper>
	);
}

const SummaryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 30px 10px 0 0;
`;

function Summary({ okCount, deviationCount, percentage }) {
	return (
		<SummaryWrapper>
			<table width="170px">
				<tbody>
					<tr>
						<td>
							<strong>Total ok</strong>
						</td>
						<td align="right">{okCount}</td>
					</tr>

					<tr>
						<td>
							<strong>Total avvik</strong>
						</td>
						<td align="right">{deviationCount}</td>
					</tr>

					<tr>
						<td>
							<strong>Prosent avvik</strong>
						</td>
						<td align="right">{percentage.toFixed(0)}&nbsp;%</td>
					</tr>
				</tbody>
			</table>
		</SummaryWrapper>
	);
}

function TotalSummarySection({ controlStatistics }) {
	const okCount = totalSum(controlStatistics, 'okCount');
	const deviationCount = totalSum(controlStatistics, 'deviationCount');
	const total = okCount + deviationCount;
	const percentage = total > 0 ? (deviationCount / total) * 100 : 0;

	return (
		<Section>
			<Summary
				okCount={okCount}
				deviationCount={deviationCount}
				percentage={percentage}
			/>
		</Section>
	);
}

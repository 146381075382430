import { Link as RouterLink } from 'react-router';
import Button from '@mui/material/Button';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import EmailIcon from '@mui/icons-material/Email';

import { useAppData } from 'web/contexts/AppDataContext';

import LoginLayout from 'web/components/LoginLayout';

export default function Login() {
	const {
		set,
		data: { invitedUser },
	} = useAppData();

	return (
		<LoginLayout
			header={<Button href="/entraid/login">Innlogging admin</Button>}
		>
			<Button
				variant="contained"
				color="primary"
				size="large"
				fullWidth={true}
				href="/bankid/login"
				startIcon={<FingerprintIcon />}
			>
				Logg inn med BankId
			</Button>

			{!invitedUser && (
				<Button
					to="/invitasjon"
					component={RouterLink}
					color="primary"
					size="large"
					variant="outlined"
					fullWidth={true}
					startIcon={<EmailIcon />}
				>
					Jeg har invitasjon
				</Button>
			)}

			{invitedUser && (
				<>
					<div>Invitasjon registrert. Vennligst logg inn!</div>

					<Button
						variant="text"
						onClick={() => set({ invitedUser: null })}
					>
						(Nullstill invitasjon)
					</Button>
				</>
			)}
		</LoginLayout>
	);
}

import { useState } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useMutation } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

import { upload } from 'utils/fileHandling';
import { formatDate } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';
import { useMoveDocumentNodeModal } from 'web/contexts/MoveDocumentNodeModalContext';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useRenameDialog } from 'web/contexts/RenameDocumentNodeModalContext';

import UnsignedDocumentsDialog from 'web/screens/dialogs/documents/UnsignedDocumentsDialog';

import Icon from 'components/Icon';
import ErrorMessage from 'components/ErrorMessage';

import { TableRow, TableCell } from 'web/components/Table';
import Link from 'web/components/Link';

import {
	DELETE_FOLDER_NODE,
	UPDATE_ONE_DOCUMENT_NODE,
	UPDATE_DOCUMENT_NODE,
	CREATE_ONE_DOCUMENT_NODE,
} from 'api/mutations/documentMutations';

const DocumentFolderRowWrapper = styled.div`
	background-color: ${p =>
		p.$isOver ? p.theme.palette.background.gray : 'transparent'};
	box-shadow: ${p =>
		p.$isOver
			? `0px 0px 0px 1px ${p.theme.palette.common.darkGray}`
			: 'none'};
	opacity: ${p => (p.$isHidden ? 0.5 : 1)};
	position: relative;
	padding-right: ${p => (p.$showMenu ? '64px' : '0')};
	transition: background-color 0.5s;
	border-bottom: 1px solid ${p => p.theme.palette.background.paper};

	&:hover {
		background-color: ${p => p.theme.palette.background.gray};
	}

	&& > * {
		grid-template-columns: repeat(5, minmax(50px, 1fr));
		grid-column: 1 / -1;
		display: grid;
	}
`;

const StyledIcon = styled(Icon)`
	margin-right: 5px;
	width: 20px;
	text-align: center;
`;

const DocumentRowMenuWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 0;
`;

const StyledTableRow = styled(TableRow)`
	& > * {
		font-weight: ${p => (p.$bold ? '600' : '400')};
	}
`;

const DocumentFolderRowButton = styled(Button)`
	&& {
		position: absolute;
		right: 0;
		top: 0;
		height: 48px;
	}

	&:hover {
		.fa-ellipsis-v {
			color: black;
		}
	}
`;

const UnsignedDocsButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	display: block;
	padding: 0;
	text-align: center;
	width: 100%;
`;

export default function DocumentFolderRow(props) {
	const {
		_id,
		name,
		linkBase,
		tenantId,
		signedDocuments,
		unsignedDocuments,
		filesCount,
		seenByCurrentUser,
		showMenu = false,
		canDelete = false,
		isHidden = false,
	} = props;

	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();
	const [error, setError] = useState(null);

	const { move } = useMoveDocumentNodeModal();
	const { rename } = useRenameDialog();

	const [anchorEl, setAnchorEl] = useState(null);
	const [showDialog, setShowDialog] = useState(false);

	const [createDocument, { error: createDocumentError }] = useMutation(
		CREATE_ONE_DOCUMENT_NODE
	);

	const [updateDocument, { error: updateDocumentError }] =
		useMutation(UPDATE_DOCUMENT_NODE);

	const [updateDocumentNode] = useMutation(UPDATE_ONE_DOCUMENT_NODE, {
		refetchQueries: ['paginateDocumentNodes'],
	});

	const [deleteFolder, { error: deleteFolderError }] = useMutation(
		DELETE_FOLDER_NODE,
		{
			refetchQueries: ['paginateDocumentNodes'],
		}
	);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: ['file', NativeTypes.FILE],
		drop: async (item, monitor) => {
			const itemType = monitor.getItemType();

			if (itemType === 'file') {
				try {
					const { data } = await updateDocumentNode({
						variables: {
							_id: item._id,
							parentId: _id,
						},
					});

					if (data?.file) {
						notify(`${item.name} ble flyttet til ${name}!`);
					}
				} catch (err) {
					console.error(err);
				}
			} else if (itemType === NativeTypes.FILE) {
				console.info('laster  opp filer');
				await handleFilesUpload(item.files);
			}

			return;
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const handleFilesUpload = async files => {
		setError(null);

		for (const file of files) {
			if (file.size > 15000000) {
				setError('Hver fil må være mindre enn 15 MB!');
				return;
			}

			if (!file.type) {
				setError('Du må laste opp gyldige filformater!');
				return;
			}

			const fileName =
				formatDate(new Date(), 'DD.MM.YYYY') + ' - ' + file.name;
			let data;

			try {
				({ data } = await createDocument({
					variables: {
						name: fileName,
						size: file.size,
						contentType: file.type,
						order: 2,
						parentId: _id,
						tenantId,
					},
				}));
			} catch (err) {
				console.error(err);
				return;
			}

			let documentState = 'ready';

			try {
				await upload(data.file.uploadUrl, file, {
					headers: {
						'Content-Type': file.type,
					},
				});
			} catch (err) {
				documentState = 'error';
				console.error(err);
			}

			try {
				await updateDocument({
					variables: {
						_id: data.file._id,
						status: documentState,
					},
					refetchQueries: ['paginateDocumentNodes'],
				});
			} catch (err) {
				console.error(err);
			}
		}

		notify(pluralize('Filen', 'Filene', files) + ' ble lastet opp!');
	};

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	return (
		<>
			<ErrorMessage
				errors={[
					error,
					createDocumentError,
					updateDocumentError,
					deleteFolderError,
				]}
			/>
			<DocumentFolderRowWrapper
				$isHidden={isHidden}
				$isOver={isOver}
				ref={drop}
			>
				<Link to={`${linkBase}${_id}/`}>
					<StyledTableRow
						$bold={!seenByCurrentUser}
						interact
						transparent
					>
						<TableCell start="1" end="3">
							<StyledIcon icon="folder" />

							<span>{name}</span>
						</TableCell>

						<TableCell center>
							{unsignedDocuments && unsignedDocuments.length ? (
								<UnsignedDocsButton
									onClick={e => {
										e.preventDefault();

										setShowDialog(true);
									}}
								>
									{unsignedDocuments.length}
								</UnsignedDocsButton>
							) : (
								<span>0</span>
							)}
						</TableCell>

						<TableCell center>
							{signedDocuments?.length || 0}
						</TableCell>

						<TableCell center>{filesCount}</TableCell>
					</StyledTableRow>
				</Link>

				{showMenu && (
					<DocumentRowMenuWrapper>
						<DocumentFolderRowButton onClick={handleClick}>
							<Icon icon="ellipsis-v" />
						</DocumentFolderRowButton>

						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
						>
							<MenuItem
								onClick={() => {
									rename({
										...props,
									});

									handleClose();
								}}
							>
								Nytt navn
							</MenuItem>

							<MenuItem
								onClick={() => {
									move({
										_id,
										tenantId,
										name,
									});
								}}
							>
								Flytt
							</MenuItem>

							{!isHidden && (
								<MenuItem
									onClick={async () => {
										if (
											await verify({
												title: 'Arkiver?',
												text: `Sikker på at du ønsker å arkivere ${name}?`,
											})
										) {
											try {
												await updateDocumentNode({
													variables: {
														_id,
														isHidden: true,
													},
												});
											} catch (err) {
												console.error(err);
											}
										}
									}}
								>
									Arkiver
								</MenuItem>
							)}

							{canDelete && (
								<MenuItem
									onClick={async () => {
										try {
											if (
												await verify({
													title: 'Slette?',
													text: (
														<>
															<div>
																Sikker på at du
																ønsker å slette
																mappen «{name}»?
															</div>
														</>
													),
												})
											) {
												await deleteFolder({
													variables: {
														_id,
													},
												});
											}
										} catch (err) {
											console.error(err);
										}
									}}
								>
									Slett
								</MenuItem>
							)}
						</Menu>
					</DocumentRowMenuWrapper>
				)}

				<UnsignedDocumentsDialog
					unsignedDocuments={unsignedDocuments}
					linkBase={linkBase}
					isOpen={showDialog}
					close={() => setShowDialog(false)}
				/>
			</DocumentFolderRowWrapper>
		</>
	);
}

import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'web/contexts/ConfirmContext';

import ErrorMessage from 'components/ErrorMessage';
import {
	Dialog,
	DialogTitle,
	DialogCloseButton,
	DialogActions,
	DialogContent,
	Button,
} from 'components/dialogs/DialogBox';
import Form, { Checkbox, CheckboxGroup } from 'web/form/Form';

import { SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION } from 'api/mutations/companyContractMutations';

const Separator = styled.div`
	display: grid;
	grid-template-columns: 32px 1fr;
	align-items: center;
	gap: 10px;
`;

export default function ResendContractSigningRequestDialog({
	contractId,
	signeeId,
	signeeName,
	signeeAccessKey,
	open,
	onClose,
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			fullWidth={true}
			maxWidth="md"
		>
			<DialogTitle id="alert-dialog-slide-title">
				<div>
					Varsling om signering av kontrakt til{' '}
					<strong>{signeeName}</strong>
				</div>

				<DialogCloseButton onClick={onClose} />
			</DialogTitle>

			<SigningRequestDialogContent
				contractId={contractId}
				signeeId={signeeId}
				signeeName={signeeName}
				signingRequestUrl={`${process.env.REACT_APP_CONTRACT_SIGN_URL}/avtaler/${contractId}?tilgangsnokkel=${signeeAccessKey}`}
				onClose={onClose}
			/>
		</Dialog>
	);
}

function SigningRequestDialogContent({
	contractId,
	signeeId,
	signeeName,
	signingRequestUrl,
	onClose,
}) {
	return (
		<>
			<DialogContent id="alert-dialog-slide-description">
				<Stack direction="column" spacing={4}>
					<SigningRequestUrlField
						signingRequestUrl={signingRequestUrl}
					/>

					<Separator>
						<strong>Eller</strong>
						<Divider component="div" />
					</Separator>

					<NoticeSigningRequestForm
						signeeId={signeeId}
						signeeName={signeeName}
						contractId={contractId}
					/>

					<Divider component="div" />
				</Stack>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Lukk</Button>
			</DialogActions>
		</>
	);
}

const SigningRequestUrlOuterGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 140px;
	margin: 10px 0 20px;
`;

const SigningRequestUrlFieldWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
`;

const SigninLinkInput = styled.input`
	background: ${p => p.theme.palette.background.paper};
	border: 1px solid ${p => p.theme.palette.divider};
	cursor: pointer;
	padding: 12px;
	width: 100%;
	border-right: none;
`;

const StyledCheckIcon = styled(CheckIcon)`
	opacity: ${p => (p.$copied ? 1 : 0)};
	transition: opacity 0.5s ease-in-out;
	position: absolute;
	top: 8px;
	right: 15px;
`;

const StyledCopyButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;

function SigningRequestUrlField({ signingRequestUrl }) {
	const { notify } = useSnackbar();

	const [copied, setCopied] = useState(false);

	return (
		<div>
			<p>Kopier varslinglenken til utklippstavlen</p>

			<SigningRequestUrlOuterGrid>
				<SigningRequestUrlFieldWrapper>
					<SigninLinkInput
						value={signingRequestUrl}
						onClick={() => {
							navigator.clipboard.writeText(signingRequestUrl);
							setCopied(true);

							notify(
								'Signeringslenke kopiert til utklippstavlen'
							);
						}}
						readOnly
					/>

					<StyledCheckIcon $copied={copied} color="success" />
				</SigningRequestUrlFieldWrapper>
				<StyledCopyButton
					variant="outlined"
					disabled={copied}
					startIcon={<ContentPasteIcon />}
					onClick={() => {
						setCopied(true);
						navigator.clipboard.writeText(signingRequestUrl);
						notify('Signeringslenke kopiert til utklippstavlen');
					}}
				>
					Kopier lenke
				</StyledCopyButton>
			</SigningRequestUrlOuterGrid>
		</div>
	);
}

function NoticeSigningRequestForm({ signeeName, contractId, signeeId }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [noticeSent, setNoticeSent] = useState(false);

	const [resendSigningRequest, { loading, error }] = useMutation(
		SEND_COMPANY_CONTRACT_SIGNING_REQUEST_NOTIFICATION
	);

	const handleSubmit = async ({ notifyEmail, notifySMS }, { setError }) => {
		if (!notifyEmail && !notifySMS) {
			setError('checkboxes', {
				type: 'manual',
				message: 'Minst ett av alternativene må være valgt',
			});
			return;
		}

		if (
			await verify({
				title: 'Send varsel',
				text: `Er du sikker på at du ønsker å sende ut et nytt varsel til ${signeeName}?`,
			})
		) {
			try {
				const { data } = await resendSigningRequest({
					variables: {
						_id: contractId,
						signingUserId: signeeId,
						notifyEmail,
						notifySMS,
					},
				});

				if (data) {
					let message = 'Varsel ble sendt på ';

					if (notifyEmail) {
						message += 'e-post';

						if (notifySMS) {
							message += ' og SMS';
						}
					} else {
						message += 'SMS';
					}

					notify(message);
					setNoticeSent(true);
				}
			} catch (err) {
				console.error(err);
			}
		}
	};

	return (
		<Form
			showErrors={false}
			values={{
				notifyEmail: true,
				notifySMS: false,
			}}
		>
			<Stack
				direction="row"
				spacing={10}
				sx={{
					alignItems: 'flex-end',
					justifyContent: 'space-between',
					mb: '10px',
				}}
			>
				<div>
					<p>Send varsel på e-post eller SMS</p>

					<ErrorMessage errors={[error]} />

					<NoticeSigningRequestFormFields noticeSent={noticeSent} />
				</div>
				<div>
					<NoticeSigningRequestSubmitButton
						onSubmit={handleSubmit}
						noticeSent={noticeSent}
						loading={loading}
						signeeName={signeeName}
					/>
				</div>
			</Stack>
		</Form>
	);
}

function NoticeSigningRequestFormFields({ noticeSent }) {
	const { clearErrors } = useFormContext();

	return (
		<CheckboxGroup name="checkboxes">
			<Stack direction="row" spacing={2}>
				<Checkbox
					name="notifyEmail"
					label="E-post"
					onFocus={() => clearErrors()}
					disabled={noticeSent}
				/>

				<Checkbox
					name="notifySMS"
					label="SMS"
					onFocus={() => clearErrors()}
					disabled={noticeSent}
				/>
			</Stack>
		</CheckboxGroup>
	);
}

function NoticeSigningRequestSubmitButton({ onSubmit, noticeSent, loading }) {
	// Because this modal is used within a parent Form, the button can not be of type="submit".
	// That will cause the parent Form to submit as well.
	// Instead we need to call the parent Form's handleSubmit function.

	const { handleSubmit, setError } = useFormContext();

	return (
		<Button
			variant="contained"
			onClick={handleSubmit(async values =>
				onSubmit(values, { setError })
			)}
			startIcon={<SendIcon />}
			disabled={noticeSent || loading}
			sx={{ mb: '10px' }}
		>
			{noticeSent ? <>Varsel sendt!</> : <>Send varsel</>}
		</Button>
	);
}

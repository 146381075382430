import { createContext, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { roles } from 'server/context/roles';

import UserVerificationsDialog from 'web/screens/dialogs/UserVerificationsDialog';

import { CURRENT_USER } from 'api/queries/userQueries';

const userRoleIds = roles.map(({ _id }) => _id);

export const UserContext = createContext({
	user: null,
});

export const useUser = () => useContext(UserContext);

export default function UserProvider({ children }) {
	const {
		data: { currentUser } = { currentUser: null },
		loading,
		refetch,
	} = useQuery(CURRENT_USER, {
		fetchPolicy: 'network-only',
	});

	const userHasOneOfRoles = roles => {
		if (!currentUser) return false;

		if (typeof roles === 'string') {
			return currentUser.role === roles;
		}

		if (Array.isArray(roles)) {
			return roles.includes(currentUser.role);
		}

		return false;
	};

	const userHasMinRole = role => {
		if (!currentUser) return false;

		if (typeof role !== 'string') {
			throw new Error('userHasMinRole: Role must be a string');
		}

		const minRoleIndex = userRoleIds.indexOf(role);
		const currentUserRoleIndex = userRoleIds.indexOf(currentUser.role);

		if (minRoleIndex === -1) {
			throw new Error(
				`userHasMinRole: Role not found. Check role name: ${role}`
			);
		}

		return currentUserRoleIndex >= minRoleIndex;
	};

	const userHasRole = role => {
		return currentUser?.role === role;
	};

	const logout = async () => {
		try {
			const response = await fetch('/auth/logout', {
				method: 'POST',
				credentials: 'include', // Sends cookies with the request
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.ok) {
				throw new Error('Failed to logout');
			}

			await refetch(); // Refetch user data (Should return null)
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<UserContext.Provider
			value={{
				userLoading: loading,
				user: currentUser,
				userHasOneOfRoles,
				userHasMinRole,
				userHasRole,
				logout,
			}}
		>
			{children}

			<UserVerificationsDialog />
		</UserContext.Provider>
	);
}

import { createContext, useState, useContext } from 'react';

const APP_DATA_KEY = process.env.REACT_APP_DATA_KEY || 'admento-web';
let APP_DATA = null;

try {
	const localData = JSON.parse(localStorage.getItem(APP_DATA_KEY));

	APP_DATA = {
		...localData,
		isSidebarOpen: localData?.isSidebarOpen ?? true,
	};
} catch (error) {
	console.warn(error);
}

const AppDataContext = createContext({
	set: () => {},
	data: APP_DATA,
});

export const useAppData = () => useContext(AppDataContext);

export default function AppDataProvider({ children }) {
	const [data, _setData] = useState(APP_DATA || {});

	function setData(values) {
		const updatedData = { ...data, ...values };

		localStorage.setItem(APP_DATA_KEY, JSON.stringify(updatedData));

		_setData(updatedData);
	}

	return (
		<AppDataContext.Provider
			value={{
				data,
				set: setData,
			}}
		>
			{children}
		</AppDataContext.Provider>
	);
}

export default () => `
# **Personvernerklæring**

**Sist oppdatert: 13.03.2025**

Vi respekterer personvernet ditt og er forpliktet til å beskytte personopplysningene dine. Denne personvernerklæringen forklarer hvilke opplysninger vi samler inn, hvordan vi bruker dem, og hvilke rettigheter du har i henhold til personvernlovgivningen.



## **1. Hvilke opplysninger vi samler inn**

Vi kan samle inn følgende typer personopplysninger:

- **Identifikasjonsopplysninger:** Navn, e-postadresse, telefonnummer
- **Bruksdata:** Informasjon om hvordan du bruker appen, inkludert logger og enhetsinformasjon
- **Lokasjonsdata:** Kun hvis du har gitt samtykke til dette
- **Andre opplysninger:** Eventuelle data du frivillig gir oss gjennom skjemaer eller kommunikasjon



## **2. Hvordan vi bruker opplysningene dine**

Vi behandler personopplysninger for følgende formål:

- For å levere og forbedre appen
- For å gi kundesupport og svare på henvendelser
- For å tilpasse brukeropplevelsen
- For å analysere bruksmønstre og forbedre tjenestene våre
- For å oppfylle juridiske forpliktelser


## **3. Deling av personopplysninger**

Vi deler ikke personopplysningene dine med tredjeparter med mindre:

- Det er nødvendig for å levere tjenesten (f.eks. skylagringsleverandører)
- Vi har et juridisk grunnlag for å gjøre det
- Du har gitt ditt samtykke

Alle tredjeparter vi samarbeider med, er forpliktet til å beskytte opplysningene dine i henhold til GDPR.


## **4. Lagring og sikkerhet**

Vi lagrer opplysningene dine så lenge det er nødvendig for formålene beskrevet i denne erklæringen, med mindre loven krever lengre lagring. Vi benytter sikkerhetstiltak for å beskytte opplysningene mot uautorisert tilgang, endring eller sletting.


## **5. Dine rettigheter**

Du har rett til å:

- Få innsyn i hvilke personopplysninger vi har om deg
- Få uriktige opplysninger rettet
- Be om sletting av opplysningene dine («retten til å bli glemt»)
- Trekke tilbake samtykke der behandlingen er basert på dette
- Be om dataportabilitet (få en kopi av dine data)
- Klage til Datatilsynet hvis du mener vi behandler opplysningene dine ulovlig

Du kan utøve dine rettigheter ved å kontakte oss på [administrasjon@falstad.no](mailto:administrasjon@falstad.no).


## **6. Endringer i personvernerklæringen**

Vi kan oppdatere denne personvernerklæringen fra tid til annen. Endringer vil bli publisert i appen, og vi anbefaler at du leser gjennom erklæringen jevnlig.


## **7. Kontaktinformasjon**

Hvis du har spørsmål om personvernerklæringen eller hvordan vi behandler personopplysningene dine, kan du kontakte oss:

**E-post:** [administrasjon@falstad.no](mailto:administrasjon@falstad.no)

**Telefon:** 74 07 00 00
`;

import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

import { useUser } from 'web/contexts/UserContext';

import {
	getVerificationOperation,
	webComponentsLookup,
	verificationKeys,
} from 'utils/userVerifications';

export default function UserVerificationsDialog() {
	const { user } = useUser();
	const [isOpen, setIsOpen] = useState(false);
	const [unverifiedVerifications, setUnverifiedVerifications] = useState([]);
	const [currentModal, setCurrentModal] = useState({ type: null, props: {} });

	const ActiveModal = webComponentsLookup[currentModal.type];

	useEffect(() => {
		if (!user) return;

		let unverified =
			verificationKeys?.reduce((prev, current) => {
				const verification = user?.userVerifications?.find(
					({ type }) => type === current
				);

				if (!verification || verification.length === 0) {
					return prev;
				}

				if (verification?.isVerified) {
					return prev;
				}

				const operation = getVerificationOperation(
					user,
					current,
					verification?.value
				);

				if (!operation) {
					return prev;
				}

				return [...prev, operation];
			}, []) || [];

		if (unverified?.length === 0) {
			//Get  session storage
			const sessionKey = 'hideUserLastVerificationDate';
			const hideUserLastVerificationDate =
				sessionStorage.getItem(sessionKey);

			// If user has chosen to hide the last verification modal, return
			if (hideUserLastVerificationDate === 'true') return;

			//Date 6 months ago
			const today = new Date();
			const sixMonthsAgo = new Date(
				today.getFullYear(),
				today.getMonth() - 6,
				today.getDate()
			);

			// Check if date from last verification is more than 6 months ago
			if (
				user.forceUserVerificationDate ||
				!user.userLastVerificationDate ||
				user.userLastVerificationDate < sixMonthsAgo.toISOString()
			) {
				unverified = [
					{
						type: 'detailsVerify',
						props: {
							user,
							setIsOpen,
							sessionKey,
						},
					},
				];
			}
		}

		setUnverifiedVerifications(unverified);
	}, [user?.updatedAt?.toString()]);

	useEffect(() => {
		if (unverifiedVerifications.length === 0) {
			setIsOpen(false);
			return;
		}

		const modal = unverifiedVerifications[0] || {
			type: null,
			props: {},
		};

		setCurrentModal(modal);
		setIsOpen(true);

		return () => {};
	}, [unverifiedVerifications]);

	return (
		<Dialog
			open={isOpen}
			scroll="body"
			aria-labelledby="user-verification-dialog-title"
		>
			{currentModal.type && <ActiveModal {...currentModal.props} />}
		</Dialog>
	);
}

import styled from 'styled-components';

import Markdown from 'react-markdown';
import privacyPolicyMarkdown from './privacyPolicyMarkdown';

const MarkdownText = styled.div`
	a {
		color: ${p => p.theme.palette.primary.main};
		text-decoration: underline;
		transition: all 0.5s;

		&:hover {
			color: ${p => p.theme.palette.text.primary};
		}
	}
`;

export default function PrivacyPolicyText() {
	const text = privacyPolicyMarkdown();

	return (
		<MarkdownText>
			<Markdown>{text}</Markdown>
		</MarkdownText>
	);
}

import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, Link } from 'react-router';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { conjunctionFormat } from 'utils/arrays';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import NewAssignmentTaskDialog from 'web/screens/dialogs/assignmentTask/NewAssignmentTaskDialog';
import EditAssignmentTaskDialog from 'web/screens/dialogs/assignmentTask/EditAssignmentTaskDialog';

import ErrorMessage from 'components/ErrorMessage';

import DesktopLayout, {
	WideContent,
	StickyAction,
} from 'web/components/Layout';

import { GET_ONE_COMPANY } from 'api/queries/tenantQueries';

import { GET_TASKS } from 'api/queries/companyAssignmentTaskQueries';
import {
	DEACTIVATE_TASKS_ACTIVE_FOR_CATEGORY,
	SET_COMPANY_ASSIGNMENT_TASK_ACTIVE_STATUS,
	DELETE_ONE_COMPANY_ASSIGNMENT_TASK,
} from 'api/mutations/companyAssignmentTaskMutations';

const Title = styled.h1`
	margin: 0 0 30px 0;
`;

const Panel = styled.div`
	background-color: ${p => p.theme.palette.background.default};
	border: 1px solid ${p => p.theme.palette.background.paper};
	padding: 20px;
	margin-bottom: 20px;
`;

export default function CompanyAssignmentTasksEdit() {
	const { companyId } = useParams();

	const {
		data: { company } = { company: null },
		loading: companyLoading,
		error: companyError,
	} = useQuery(GET_ONE_COMPANY, {
		variables: {
			_id: companyId,
		},
	});

	const {
		data: { tasks } = { tasks: [] },
		loading: tasksLoading,
		error: tasksError,
	} = useQuery(GET_TASKS, {
		variables: {
			companyId,
		},
	});

	const tasksGroupedByCategory = tasks
		?.reduce((categories, task) => {
			let categoryGroup = categories.find(
				group => group.category.name === task.category.name
			);

			if (!categoryGroup) {
				categoryGroup = {
					category: task.category,
					tasks: [],
				};

				categories.push(categoryGroup);
			}

			categoryGroup.tasks.push(task);

			return categories;
		}, [])
		.sort((a, b) => a.category.order - b.category.order);

	return (
		<DesktopLayout
			title="Planlegg oppdrag"
			loading={companyLoading || tasksLoading}
			breadcrumbs={[
				{
					to: '/bedrifter/',
					label: 'Bedrifter',
				},
				{
					to: `/bedrifter/${companyId}/rediger/`,
					label: company?.name || '...',
				},
				{
					to: `/bedrifter/${companyId}/rediger/?tab=planlegging`,
					label: 'Oppdrag',
				},
				{
					label: 'Planlegg',
				},
			]}
		>
			<WideContent>
				<Title>Planlegg oppdrag</Title>

				<ErrorMessage errors={[companyError, tasksError]} />

				{!tasksLoading && tasksGroupedByCategory.length === 0 ? (
					<div>
						<p>
							Det er ikke opprettet noen oppgaver for denne
							bedriften ennå.
						</p>

						<p>
							<Button
								type="button"
								variant="contained"
								size="large"
								component={Link}
								to={`/bedrifter/${companyId}/oppdrag/ny/`}
							>
								Opprett standard oppgaver
							</Button>
						</p>
					</div>
				) : (
					<div>
						{tasksGroupedByCategory.map(
							(categoryGroup, categoryIndex) => (
								<CategoryPanel
									key={categoryIndex}
									companyId={companyId}
									categoryGroup={categoryGroup}
								/>
							)
						)}

						<StickyAction>
							<Button
								type="button"
								variant="contained"
								size="large"
								component={Link}
								to={`/bedrifter/${companyId}/rediger/?tab=planlegging`}
							>
								Fullfør planlegging
							</Button>
						</StickyAction>
					</div>
				)}
			</WideContent>
		</DesktopLayout>
	);
}

function CategoryPanel({ companyId, categoryGroup }) {
	const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);

	const [deactivate, { loading, error }] = useMutation(
		DEACTIVATE_TASKS_ACTIVE_FOR_CATEGORY,
		{
			refetchQueries: ['getTasks'],
		}
	);

	return (
		<div>
			<ErrorMessage errors={[error]} />

			<SectionPanel
				title={categoryGroup.category.name}
				primaryAction={
					<Button
						type="button"
						disabled={loading}
						onClick={async () => {
							try {
								await deactivate({
									variables: {
										companyId,
										categoryName:
											categoryGroup.category.name,
									},
								});
							} catch (err) {
								console.error(err);
							}
						}}
					>
						Deaktiver
					</Button>
				}
				secondaryAction={
					<Button
						type="button"
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setShowAddTaskDialog(true)}
					>
						Legg til oppgave
					</Button>
				}
			>
				<List>
					{categoryGroup.tasks.map(task => (
						<TaskListItem key={task._id} task={task} />
					))}
				</List>
			</SectionPanel>

			<NewAssignmentTaskDialog
				companyId={companyId}
				category={categoryGroup.category}
				open={showAddTaskDialog}
				onClose={() => setShowAddTaskDialog(false)}
			/>
		</div>
	);
}

function TaskListItem({ task }) {
	const { verify } = useConfirmDialog();
	const { notify } = useSnackbar();

	const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

	const [
		setActiveStatus,
		{ loading: setActiveStatusLoading, error: setActiveStatusError },
	] = useMutation(SET_COMPANY_ASSIGNMENT_TASK_ACTIVE_STATUS, {
		refetchQueries: ['getTasks'],
	});

	const [deleteTask, { loading: deleteTaskLoading, error: deleteTaskError }] =
		useMutation(DELETE_ONE_COMPANY_ASSIGNMENT_TASK, {
			refetchQueries: ['getTasks'],
		});

	return (
		<div>
			<ErrorMessage errors={[setActiveStatusError, deleteTaskError]} />

			<ListItem
				key={task._id}
				secondaryAction={
					<>
						{task.custom && (
							<IconButton
								edge="end"
								aria-label="Slett oppgave"
								title="Rediger oppgave"
								disabled={deleteTaskLoading}
								onClick={async () => {
									try {
										if (
											!(await verify({
												title: 'Slett oppgave',
												text: `Er du sikker på at du vil slette oppgaven «${task.name}» med tilhørende tidsfrister?`,
											}))
										) {
											return;
										}

										const { data } = await deleteTask({
											variables: {
												_id: task._id,
											},
										});

										if (data.deleted) {
											notify('Oppgaven ble slettet!');
										}
									} catch (err) {
										console.error(err);
									}
								}}
							>
								<DeleteIcon />
							</IconButton>
						)}

						<IconButton
							edge="end"
							aria-label="Rediger oppgave"
							title="Rediger oppgave"
							onClick={() => setShowEditTaskDialog(true)}
						>
							<EditIcon />
						</IconButton>
					</>
				}
				disablePadding
			>
				<FormControlLabel
					control={
						<Switch
							checked={task.active}
							disabled={setActiveStatusLoading}
							onChange={async () => {
								try {
									const { data } = await setActiveStatus({
										variables: {
											_id: task._id,
											active: !task.active,
										},
									});

									if (typeof data.status === 'boolean') {
										notify(
											`Oppgaven ble ${
												data?.status
													? 'aktivert'
													: 'deaktivert'
											}!`
										);
									}
								} catch (err) {
									console.error(err);
								}
							}}
						/>
					}
					label={<Task task={task} />}
				/>

				<EditAssignmentTaskDialog
					task={task}
					open={showEditTaskDialog}
					onClose={() => setShowEditTaskDialog(false)}
				/>
			</ListItem>
		</div>
	);
}

const TaskWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
`;

const TaskColumn = styled.div`
	color: ${p => p.theme.palette.text.secondary};
`;

const NameColumn = styled.div`
	width: 370px;
`;

const FrequencyColumn = styled(TaskColumn)`
	width: 150px;
`;

const DayColumn = styled(TaskColumn)`
	width: 20px;
`;

function Task({ task }) {
	const { name, frequencyLabel, deadline } = task;

	return (
		<TaskWrapper>
			<NameColumn>{name}</NameColumn>

			<FrequencyColumn>{frequencyLabel}</FrequencyColumn>

			{deadline?.weekday != null && (
				<TaskColumn>{deadline.weekdayLabel}</TaskColumn>
			)}

			{deadline?.day != null && <DayColumn>{deadline.day}</DayColumn>}

			{deadline?.relativeMonth != null && (
				<TaskColumn>{deadline.relativeMonthLabel}</TaskColumn>
			)}

			{deadline?.month != null && (
				<TaskColumn>{deadline.monthLabel}</TaskColumn>
			)}

			{deadline?.dates && deadline.dates.length > 0 && (
				<TaskColumn>
					<TaskCustomDeadlines dates={deadline.dates} />
				</TaskColumn>
			)}
		</TaskWrapper>
	);
}

const TaskCustomDeadlinesWrapper = styled.div`
	margin-left: 35px;
`;

function TaskCustomDeadlines({ dates }) {
	const dateStrings = dates.map(({ day, month }) => {
		const dayString = day.toString().padStart(2, '0');
		const monthString = (month + 1).toString().padStart(2, '0');

		return `${dayString}.${monthString}`;
	});

	return (
		<TaskCustomDeadlinesWrapper>
			{conjunctionFormat(dateStrings)}
		</TaskCustomDeadlinesWrapper>
	);
}

const SectionPanelWrapper = styled.div`
	margin-bottom: 40px;
`;
const SectionPanelHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const SectionPanelTitle = styled.h3`
	margin: 0;
	display: flex;
	align-items: center;
	gap: 20px;
`;

function SectionPanel({
	title,
	primaryAction = null,
	secondaryAction = null,
	children,
}) {
	return (
		<SectionPanelWrapper>
			<SectionPanelHeader>
				<SectionPanelTitle>
					<div>{title}</div>

					{primaryAction && <div>{primaryAction}</div>}
				</SectionPanelTitle>

				{secondaryAction && <div>{secondaryAction}</div>}
			</SectionPanelHeader>

			<Panel>{children}</Panel>
		</SectionPanelWrapper>
	);
}

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function DateProvider({ children }) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
			{children}
		</LocalizationProvider>
	);
}

import { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from 'web/contexts/SnackbarContext';
import { useConfirmDialog } from 'web/contexts/ConfirmContext';

import ImageUploadDialog from 'web/screens/dialogs/files/ImageUploadDialog';

import ErrorMessage from 'components/ErrorMessage';

import { GET_FILES } from 'api/queries/fileQueries';
import { DELETE_ONE_FILE } from 'api/mutations/fileMutations';

const Wrapper = styled.div`
	margin-bottom: 20px;
`;

export default function ProfileImage({ userId }) {
	const {
		data: { files = [] } = { files: [] },
		loading,
		error,
		refetch,
	} = useQuery(GET_FILES, { variables: { docId: userId } });

	if (loading) return null;

	return (
		<Wrapper>
			<h3>Profilbilde</h3>

			<ErrorMessage errors={[error]} />

			{files.length > 0 ? (
				<Image file={files[0]} />
			) : (
				<ProfileImageUploader
					userId={userId}
					onUploadCompleted={refetch}
				/>
			)}
		</Wrapper>
	);
}

const ImageWrapper = styled.div`
	width: 200px;
	height: 200px;
	position: relative;
`;

const Action = styled.div`
	position: absolute;
	top: 0;
	right: 0;
`;

const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

function Image({ file }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [deleteOneFile, { loading, error }] = useMutation(DELETE_ONE_FILE, {
		refetchQueries: [
			{
				query: GET_FILES,
				variables: { docId: file.docId },
			},
		],
	});

	return (
		<div>
			<ErrorMessage errors={[error]} />

			<ImageWrapper>
				<Action>
					<IconButton
						title="Slett bilde"
						size="large"
						disabled={loading}
						onClick={async () => {
							try {
								if (
									!(await verify({
										title: 'Slett fil',
										text: `Er du sikker på at du vil slette ${file.fileName}?`,
									}))
								) {
									return;
								}

								const { data } = await deleteOneFile({
									variables: {
										_id: file._id,
									},
								});

								if (data?.deleted) {
									notify(`${file.fileName} ble slettet!`);
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						<DeleteIcon />
					</IconButton>
				</Action>

				<Img src={file.url} alt={file.fileName} />
			</ImageWrapper>
		</div>
	);
}

function ProfileImageUploader({ userId, onUploadCompleted }) {
	const [showDialog, setShowDialog] = useState(false);

	return (
		<div>
			<Button variant="outlined" onClick={() => setShowDialog(true)}>
				Velg bilde
			</Button>

			<ImageUploadDialog
				docId={userId}
				uploadContext="profileImage"
				open={showDialog}
				onClose={() => {
					setShowDialog(false);
					onUploadCompleted();
				}}
			/>
		</div>
	);
}

import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import { useConfirmDialog } from 'web/contexts/ConfirmContext';
import { useSnackbar } from 'web/contexts/SnackbarContext';

import { formatDate } from 'utils/dates';

import SetCompanyAnnualFinancialStatusDialog from 'web/components/panels/dialogs/SetCompanyAnnualFinancialStatusDialog';

import ErrorAlert from 'components/ErrorAlert';
import Panel, { EmptyPanel } from 'web/components/panels/Panel';

import { REMOVE_ANNUAL_FINANCIAL_STATUS } from 'api/mutations/tenantMutations';

const Text = styled.div`
	color: ${p => p.theme.palette.status.subdued};
	font-size: 0.9rem;
	margin-top: 5px;
`;

export default function CompanyAnnualFinancialStautsPanel({
	companyId,
	annualFinancialStatus = null,
}) {
	const [showDialog, setShowDialog] = useState(false);

	return (
		<>
			<Panel
				title="Regnskapsstatus"
				action={
					<Button
						endIcon={<CheckIcon size="small" />}
						size="medium"
						variant="contained"
						color="primary"
						onClick={() => {
							setShowDialog(true);
						}}
					>
						Sett klar
					</Button>
				}
			>
				<CompanyAnnualFinancialStautsList
					annualFinancialStatus={annualFinancialStatus}
					companyId={companyId}
				/>
			</Panel>

			<SetCompanyAnnualFinancialStatusDialog
				annualFinancialStatus={annualFinancialStatus}
				companyId={companyId}
				open={showDialog}
				onClose={() => {
					setShowDialog(false);
				}}
			/>
		</>
	);
}

function CompanyAnnualFinancialStautsList({
	annualFinancialStatus = [],
	companyId,
}) {
	if (!annualFinancialStatus || annualFinancialStatus.length === 0) {
		return (
			<EmptyPanel>
				Det finnes ingen år med gjennomgåtte regnskap.
			</EmptyPanel>
		);
	}

	return (
		<List>
			{annualFinancialStatus.map((status, index) => (
				<CompanyAnnualFinancialStautsListItem
					key={index}
					status={status}
					companyId={companyId}
				/>
			))}
		</List>
	);
}

function CompanyAnnualFinancialStautsListItem({ status, companyId }) {
	const { notify } = useSnackbar();
	const { verify } = useConfirmDialog();

	const [removeAnnualFinancialSatus, { loading, error }] = useMutation(
		REMOVE_ANNUAL_FINANCIAL_STATUS,
		{
			refetchQueries: ['findOneTenant'],
		}
	);

	return (
		<>
			<ErrorAlert error={error} />

			<ListItem
				secondaryAction={
					<IconButton
						edge="end"
						aria-label="Slett"
						role="button"
						disabled={loading}
						onClick={async () => {
							try {
								if (
									!(await verify({
										title: `Slette regnskapsstatus`,
										text: `Er du sikker på at du vil slette status for regnskapsåret ${status.reviewedYear}?`,
									}))
								) {
									return;
								}

								const { data } =
									await removeAnnualFinancialSatus({
										variables: {
											companyId: companyId,
											year: status.reviewedYear,
										},
									});

								if (data.removed) {
									notify(
										`Regnskapsstatus ${status.reviewedYear} ble slettet`
									);
								}
							} catch (err) {
								console.error(err);
							}
						}}
					>
						<DeleteIcon />
					</IconButton>
				}
			>
				<ListItemText>
					<div>Regnskapsår {status.reviewedYear} </div>

					<Text>
						{status.notRelevant ? (
							<>
								Merket som <strong>ikke relevant</strong>{' '}
								av{' '}
							</>
						) : (
							<>Satt klar for gjennomgang av </>
						)}
						{status.reviewedByDisplayName}

						{' - '}
						{formatDate(status.reviewedAt, 'DD.MM.YY [kl] HH:mm')}
					</Text>
				</ListItemText>
			</ListItem>

			<Divider component="li" />
		</>
	);
}
